import Button from "@mui/material/Button";
import { ethers, utils } from "ethers";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IntlMessages from "../../../@crema/utility/IntlMessages";
import { connectRequest, sign1Request, sign2Request } from "../../../redux/auth/actions";
import { Fonts } from "../../../shared/constants/AppEnums";
declare global {
  interface Window {
    ethereum?: any;
    web3?: any;
  }
}

const MetaMaskLogin = () => {
  const dispatch = useDispatch();
  const signMessageData = useSelector((state: any) => state.auth.signMessage);
  const [signNo, setSignNo] = useState<number>(1)

  useEffect(() => {
    if (signMessageData) {
      handleSign(signMessageData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signMessageData])

  const handleSign = async (signMessageData) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const hash = await signer.signMessage(signMessageData);
    const address = await signer.getAddress();
    if (signNo === 1) {
      dispatch(sign1Request({ walletAddress: address, hash: hash }));
      setSignNo(2)
    } else {
      dispatch(sign2Request({ walletAddress: address, hash: hash }));
      // dispatch(staticAddressesRequest({ walletAddress: address }));

      setSignNo(1)
    }
  }

  const onLoginHandler = async () => {
    // console.log("connect")
    if (!window?.ethereum)
      console.log("No crypto wallet found. Please install it.");
    
    const response = await window?.ethereum?.request({ method: 'eth_requestAccounts' })
    if (response) {
      const address = utils?.getAddress(response[0]);
      dispatch(connectRequest({ walletAddress: address }));
    } else {
      toast.error("No crypto wallet found. Please install it.");
    }
  };

  return (
    
        <Button
          variant="contained"
          color="primary"
          onClick={onLoginHandler}
          sx={{
            minWidth: 160,
            fontWeight: Fonts.REGULAR,
            fontSize: 16,
            textTransform: "capitalize",
            padding: "4px 16px 8px",
          }}
          disabled={signMessageData ? true : false}
        >
          {signMessageData ?  "Connecting..." : <IntlMessages id="common.connect" />}
        </Button>
      
  );
};

export default MetaMaskLogin;
