export const GET_ALL_COLLECTIONS = "get_all_collections";
export const GET_ALL_COLLECTIONS_SUCCESS = "get_all_collection_success";
export const GET_ALL_COLLECTIONS_ROWS_LIMIT = "get_all_collection_rowLimit";


export const GET_USER_COLLECTIONS = "get_user_collections";
export const GET_USER_COLLECTIONS_SUCCESS = "get_user_collection_success";

export const REGISTER_COLLECTION_REQUEST = "register_collection_request";
export const REGISTER_COLLECTION_SUCCESS = "register_collection_success";

export const DASHBOARD_COLLECTION_REQUEST = "dashboard_collection_request";
export const DASHBOARD_COLLECTION_SUCCESS = "dashboard_collection_success";

export const CHANGE_PAGE = "change_page";


