import {
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_UNREAD_NOTIFICATION_SUCCESS,
  CHANGE_PAGE,
  NOTIFICATION_COUNT,
} from "./constants";

export const getNotificationsRequest = (data: any) => ({
  type:  GET_NOTIFICATION_REQUEST,
  payload: data,
});

export const getNotificationsSuccess = (data: any) => ({
  type: GET_NOTIFICATION_SUCCESS,
  payload: data,
});
export const getUnreadNotificationsSuccess = (data: any) => ({
  type: GET_UNREAD_NOTIFICATION_SUCCESS,
  payload: data,
});
export const changePageNumber = (data: any) => ({
  type: CHANGE_PAGE,
  payload: data,
});
export const notificationCount = (data: any) => ({
  type: NOTIFICATION_COUNT,
  payload: data,
});





