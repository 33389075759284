import React from 'react';

export const authorizedRouteConfigs = [
  // {
  //   path: '/sample/page-1',
  //   component: React.lazy(() => import('../pages/sample/Page1')),
  // },
  // {
  //   path: '/home',
  //   component: React.lazy(() => import('../pages/home')),
  // },
  {
    path: '/my-nfts',
    component: React.lazy(() => import('../pages/nfts')),
  },
  {
    path: '/battles/free-battles',
    component: React.lazy(() => import('../pages/battles/BattleRoomsScreen/index')),
  },
  {
    path: '/battles/free-battles/slots',
    component: React.lazy(() => import('../pages/battles/RoomsDetailScreen/index')),
  },
  {
    path: '/notifications',
    component: React.lazy(() => import('../pages/notifications/index')),
  },
  // {
  //   path: '/battles',
  //   component: React.lazy(() => import('../pages/battles')),
  // },
  // {
  //   path: '/battle-detail',
  //   component: React.lazy(() => import('../pages/battles/BattleDetailsScreen')),
  // },
  // {
  //   path: '/leader-board',
  //   component: React.lazy(() => import('../pages/leaderBoard')),
  // },
  // {
  //   path: '/register-collection',
  //   component: React.lazy(() => import('../pages/collections')),
  // },
  {
    path: '/my-account',
    component: React.lazy(() => import('../pages/Account')),
  },
];
