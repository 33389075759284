import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { IconButton, Theme } from "@mui/material";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { alpha } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePageNumber,
  getNotificationsRequest,
} from "../../../redux/notifications/actions";
import AppTooltip from "../AppTooltip";
import AppNotificationContent from "./AppNotificationContent";
interface AppNotificationsProps {
  drawerPosition?: "left" | "top" | "right" | "bottom";
  tooltipPosition?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  isMenu?: boolean;
  sxNotificationContentStyle?: SxProps<Theme>;
}

const AppNotifications: React.FC<AppNotificationsProps> = ({
  drawerPosition = "right",
  tooltipPosition = "bottom",
  isMenu = false,
  sxNotificationContentStyle = {},
}) => {
  const [showNotification, setShowNotification] = useState(false);
  const count: any = useSelector((state: any) => state?.notifications?.count);
  const dispatch = useDispatch();

  return (
    <>
      {isMenu ? (
        <Box component="span" onClick={() => setShowNotification(true)}>
          Message
        </Box>
      ) : (
        <AppTooltip title="Notification" placement={tooltipPosition}>
          <IconButton
            className="icon-btn"
            sx={{
              borderRadius: "50%",
              width: 40,
              height: 40,
              marginLeft: { sm: 0, lg: 5 },
              color: (theme) => theme.palette.text.secondary,
              backgroundColor: (theme) => theme.palette.background.default,
              border: 1,
              borderColor: "transparent",
              "&:hover, &:focus": {
                color: (theme) => theme.palette.text.primary,
                backgroundColor: (theme) =>
                  alpha(theme.palette.background.default, 0.9),
                borderColor: (theme) =>
                  alpha(theme.palette.text.secondary, 0.25),
              },
            }}
            onClick={() => {
              setShowNotification(true);
              dispatch(
                getNotificationsRequest({ type: "unread", page: 1, limit: 15 })
              );
              dispatch(changePageNumber(0));
            }}
            size="large"
          >
            <Badge badgeContent={count} color="primary">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.8999 18.7536L21.6732 18.5536C21.0302 17.9807 20.4673 17.3236 19.9999 16.6003C19.4894 15.602 19.1834 14.5117 19.0999 13.3936V10.1003C19.1043 8.344 18.4672 6.64658 17.3084 5.32691C16.1495 4.00724 14.5486 3.15617 12.8065 2.93359V2.07359C12.8065 1.83755 12.7128 1.61118 12.5459 1.44427C12.379 1.27736 12.1526 1.18359 11.9165 1.18359C11.6805 1.18359 11.4541 1.27736 11.2872 1.44427C11.1203 1.61118 11.0265 1.83755 11.0265 2.07359V2.94693C9.30004 3.18555 7.71852 4.04176 6.57489 5.357C5.43126 6.67223 4.80302 8.35736 4.80654 10.1003V13.3936C4.72304 14.5117 4.41706 15.602 3.90654 16.6003C3.44712 17.3218 2.89333 17.9788 2.25987 18.5536L2.0332 18.7536V20.6336H21.8999V18.7536Z"
                  fill="white"
                />
                <path
                  d="M10.2129 21.333C10.2714 21.7556 10.4808 22.1427 10.8024 22.423C11.1241 22.7032 11.5363 22.8576 11.9629 22.8576C12.3895 22.8576 12.8017 22.7032 13.1234 22.423C13.445 22.1427 13.6544 21.7556 13.7129 21.333H10.2129Z"
                  fill="white"
                />
              </svg>
            </Badge>
          </IconButton>
        </AppTooltip>
      )}

      <Drawer
        // sx={{backgroundColor:"transparent"}}
        PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
        anchor={drawerPosition}
        open={showNotification}
        onClose={() => setShowNotification(false)}
      >
        <AppNotificationContent
          sxStyle={sxNotificationContentStyle}
          onClose={() => setShowNotification(false)}
        />
      </Drawer>
    </>
  );
};

export default AppNotifications;
