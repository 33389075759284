import { all, fork, put, select, takeLatest } from "redux-saga/effects";
import axios from "../../routes/axiosConfig";
import { sagaErrorHandler } from "../../shared/helperMethods/sagaErrorHandler";
import { makeSelectAuthToken } from "../store/selectors";
import {
  getAllCollectionsSuccess,
  getUserCollectionSuccess,
  registerCollectionSuccess,
  dashboardCollectionSuccess,
} from "./actions";
import {
  GET_ALL_COLLECTIONS,
  GET_USER_COLLECTIONS,
  REGISTER_COLLECTION_REQUEST,
  DASHBOARD_COLLECTION_REQUEST,
} from "./constants";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
// Login Saga //
function* getAllCollectionsSaga(): any {
  const token = yield select(makeSelectAuthToken());

  try {
    const response = yield axios.get(
      `/collection/unblocked-approved-collection?pagination=0`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(getAllCollectionsSuccess(response.data.data));
  } catch (error: any) {
    // yield sagaErrorHandler(error.response);
  }
}

function* getUserCollectionSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());

  try {
    const response = yield axios.get(
      `/collection/user/${payload.userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // toast.success("Collection Successfully Registered")

    yield put(getUserCollectionSuccess(response.data.data));
  } catch (error: any) {
    // yield sagaErrorHandler(error.response);
  }
}

function* registerCollectionRequestSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());

  console.log("payload.data", payload.data)
  try {
    const response = yield axios.post(`/collection/register`, payload.data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    yield put(registerCollectionSuccess(response.data.data));
    toast.success(response.data.data.message);
    payload.setReset();
    // yield put(setLoader(false));

    // yield put(push("/home"));
  } catch (error: any) {
    
    yield sagaErrorHandler(error.response);
  }
}

function* dashboardCollectionRequestSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());

  try {
    const response = yield axios.get( `/collection/all-collection-details?limit=${payload.limit}&page=${payload.page}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(dashboardCollectionSuccess(response.data.data));
    toast.success(response.data.data.message);
   
    
  } catch (error: any) {
    
    yield sagaErrorHandler(error.response);
  }
}

function* watchAllCollections() {
  yield takeLatest(GET_ALL_COLLECTIONS, getAllCollectionsSaga);
}

function* watchUserCollections() {
  yield takeLatest(GET_USER_COLLECTIONS, getUserCollectionSaga);
}

function* watchRegisterCollection() {
  yield takeLatest(REGISTER_COLLECTION_REQUEST, registerCollectionRequestSaga);
}
function* watchDashboardCollections() {
  yield takeLatest(DASHBOARD_COLLECTION_REQUEST, dashboardCollectionRequestSaga);
}
export default function* collectionSaga() {
  yield all([
    fork(watchAllCollections),
    fork(watchUserCollections),
    fork(watchRegisterCollection),
    fork(watchDashboardCollections),
  ]);
}
