import produce from "immer";

import {
  CONNECT_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
  SET_LOADER,
  LOGOUT,
  SIGN2_SUCCESS,
  SIGN1_SUCCESS,
 DEPOSIT_SUCCESS,
 WITHDRAW_SUCCESS,
 GET_USER_SUCCESS,

} from "./constants";

const initialState = {
  user: null,
  token: null,
  loader: false,
  signMessage: null,
};

const Auth = produce((state = initialState, action: any) => {
  switch (action.type) {
    case CONNECT_SUCCESS:
      state.signMessage = action.payload.user.permanentPin;
      state.token = action.payload.token;

      break;
    case SIGN1_SUCCESS:
      state.signMessage = action.payload.user.temporaryPin;
      break;
    case SIGN2_SUCCESS:
      state.user = action.payload.user;
  
      state.token = action.payload.token;
    break;
    case UPDATE_PROFILE_SUCCESS:
      state.user = action.payload;

      break;

      case GET_USER_SUCCESS:
        state.user = action.payload;
  
        break;
    case SET_LOADER:
      state.loader = action.payload;
      break;
      case DEPOSIT_SUCCESS:
        state.user.userBalance = action.payload.newBalance;
        break;
        case WITHDRAW_SUCCESS:
          state.user.userBalance = action.payload.remainingBalance;
          break;
    case LOGOUT:
      state.user = null;
      state.token = null;
      state.signMessage = null;
      break;
    default:
  }
}, initialState);

export default Auth;
