let Images = {
    ATTACK: require("../../assets/icon/attack.svg").default,
    DEFENSE: require("../../assets/icon/defense.svg").default,
    DAMAGE: require("../../assets/icon/damage.svg").default,
    HEALING: require("../../assets/icon/healing.svg").default,
    XP: require("../../assets/icon/xp.svg").default,
    INDEX1:require("../../assets/icon/Index1.svg").default,
    INDEX2:require("../../assets/icon/Index2.svg").default,
    INDEX3:require("../../assets/icon/Index3.svg").default,
    INDEX4:require("../../assets/icon/Index4.svg").default,
    INDEX5:require("../../assets/icon/Index5.svg").default,
    NO_DATA_Image:require("../../assets/images/data-not-found.png").default,
    HOME_ICON:require("../../assets/icon/home.svg").default,
    BATTLE_ICON:require("../../assets/icon/battleIcon.svg").default,
    COLLECTION_ICON:require("../../assets/icon/collectionIcon.svg").default,
    NFT_ICON:require("../../assets/icon/nftIcon.svg").default,
    LEADERBOARD_ICON:require("../../assets/icon/leaderboardIcon.svg").default,
    NFT_LOGO:require("../../assets/icon/nftLogo.svg").default,
    BATTLE_BACKGROUND:require("../../assets/images/battleBackground.svg").default,
    WEBSITE_ICON:require("../../assets/icon/websiteIcon.svg").default,
    TWITTER_ICON:require("../../assets/icon/twitterIcon.svg").default,
    MEDIUM_ICON:require("../../assets/icon/mediumIcon.svg").default,
    TELEGRAM_ICON:require("../../assets/icon/telegramIcon.svg").default,
    OPENSEA_ICON:require("../../assets/icon/openseaIcon.svg").default,
    INSTAGRAM_ICON:require("../../assets/icon/instagramIcon.svg").default,
    DISCORD_ICON:require("../../assets/icon/discordIcon.svg").default,
    CANCEL_ICON:require("../../assets/icon/cancelIcon.svg").default,
    SETTING_ICON:require("../../assets/icon/settingIcon.svg").default,
    LOGOUT_ICON:require("../../assets/icon/logoutIcon.svg").default,
    LOADING_IMAGE:require("../../assets/images/loading.gif").default,
    CHARACTER_1:require("../../assets/images/battleCharacter.png").default,
    CHARACTER_2:require("../../assets/images/pendingBattleCharacter.png").default,
    CHARACTER_3:require("../../assets/images/Character3.png").default,
    CHARACTER_4:require("../../assets/images/character_4.png").default,
    NFT_BANNER:require("../../assets/images/nftBannerImage.svg").default,
    BANNER1:require("../../assets/images/Banner1.svg").default,
    BANNER2:require("../../assets/images/Banner2.svg").default,
    BANNER3:require("../../assets/images/Banner3.svg").default,
    BANNER4:require("../../assets/images/Banner4.svg").default,
    LARGE_BANNER_1:require("../../assets/images/Banner1_1280x250.svg").default,
    LARGE_BANNER_2:require("../../assets/images/Banner2_1280x250.svg").default,
    LARGE_BANNER_3:require("../../assets/images/Banner3_1280x250.svg").default,
    LARGE_BANNER_4:require("../../assets/images/Banner4_1280x250.svg").default,
    LARGE_BANNER_5:require("../../assets/images/Banner5_1280x250.svg").default,
   
}

export default Images;