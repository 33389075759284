
export const GET_SINGLE_COLLECTION_NFTS_REQUEST = "get_single_collection_nft_request";
export const GET_SINGLE_COLLECTION_NFTS_SUCCESS = "get_single_collection_nft_success";

export const GET_NFT_TRAITS_REQUEST = "get_nft_traits_request";
export const GET_NFT_TRAITS_SUCCESS = "get_nft_traits_success";

export const UPGRADE_NFT_TRAITS_REQUEST = "update_nft_traits_request";
export const UPGRADE_NFT_TRAITS_SUCCESS = "upgrade_nft_traits_request";

export const GET_SINGLE_NFT_DETAILS_REQUEST = "get_single_nft_details_request";
export const GET_SINGLE_NFT_DETAILS_SUCCESS= "get_single_nft_details_success";

export const GET_NFT_REQUEST = "get_nft_request";
export const GET_NFT_SUCCESS = "get_nft_success";

export const CHANGE_PAGE = "change_page";











