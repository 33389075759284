// import {
//     Fonts,
//     FooterType,
//     HeaderType,
//     LayoutDirection,
//     LayoutType,
//     MenuStyle,
//     NavStyle,
//     RouteTransition,
//     ThemeMode,
//     ThemeStyle,
//     ThemeStyleRadius,
//   } from "shared/constants/AppEnums";

let Gradients = {
  backgroundGradient: 'linear-gradient(180deg, #393939 0%, #1D1E22 100%)',
  cardGradient: 'linear-gradient(262.28deg, #1F1F1F 1.13%, #2C2C2C 49.35%, #252525 97.58%)',
  navBarGradient: 'linear-gradient(180deg, #393939 0%, #1D1E22 100%);'
}

export default Gradients;