import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import orange from "@mui/material/colors/orange";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { useHistory } from "react-router-dom";
import { Fonts } from "../../../../../shared/constants/AppEnums";
import { useAuthMethod, useAuthUser } from "../../../../utility/AuthHooks";
import Images from "../../../../../shared/constants/AppImages";
import {walletFormat} from "../../../../../shared/helperMethods/walletFormatting";

import Divider from "@mui/material/Divider";
interface UserInfoProps {
  color?: string;
}

const UserInfo: React.FC<UserInfoProps> = ({ color = "text.secondary" }) => {
  const { logout } = useAuthMethod();
  const { user } = useAuthUser();
  const history = useHistory();
  const { isAuthenticated } = useAuthUser();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserAvatar = () => {
    if (user?.userName) {
      return user?.userName?.charAt(0).toUpperCase();
    }
    if (user?.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };

  return (
    <>
      {isAuthenticated && (
        <>
          <Box
            onClick={handleClick}
            sx={{
              py: 3,
              px: 3,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            className="user-info-view"
          >
            <Box sx={{ py: 0.5 }}>
              {user?.profileImg ? (
                <Avatar
                  sx={{
                    height: 40,
                    width: 40,
                    fontSize: 24,
                    backgroundColor: orange[500],
                  }}
                  src={user.profileImg}
                />
              ) : (
                <Avatar
                  sx={{
                    height: 40,
                    width: 40,
                    fontSize: 24,
                    backgroundColor: orange[500],
                  }}
                >
                  {getUserAvatar()}
                </Avatar>
              )}
            </Box>
            <Box
              sx={{
                width: { xs: "calc(100% - 62px)", xl: "calc(100% - 72px)" },
                ml: 4,
                color: color,
              }}
              className="user-info"
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    mb: 0,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: 16,
                    fontWeight: Fonts.MEDIUM,
                    color: "inherit",
                  }}
                  component="span"
                >
                  {user?.userName ? user?.userName : "----"}
                </Box>
                <Box
                  sx={{
                    ml: 3,
                    color: "inherit",
                    display: "flex",
                  }}
                >
                  <ExpandMoreIcon />
                </Box>
              </Box>
              <Box
                sx={{
                  mt: -0.5,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  color: "inherit",
                }}
              ></Box>
            </Box>
          </Box>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            
            <Box
              sx={{
                mt: -0.5,
                px: 3,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                color: "inherit",
                display: "flex",
              }}
            >
              <Avatar
                sx={{
                  height: 40,
                  width: 40,
                  fontSize: 24,
                  backgroundColor: "rgba(12, 218, 107, 1)",
                  // display: "inline-block",
                }}
                src={user.profileImg}
              />

              <Box
                sx={{
                  // display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  ml: 2,
                }}
              >
                {user?.userName ? user.userName : "----"}
                <Box
                  style={{
                    width: 120,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    // textOverflow: "ellipsis",
                  }}
                  sx={{
                    mt: 0,

                    color: "rgba(12, 218, 107, 1)",
                  }}
                >
                  {walletFormat(user?.walletAddress)}
                </Box>
              </Box>
            </Box>

            <Divider
              variant="middle"
              sx={{
                my: { xs: 2, md: 2 },
                border: "1px solid #E3E3E3",
              }}
            />

            <MenuItem
              onClick={() => {
                history.push("/my-account");
                setAnchorEl(null);
              }}
            >
              <img src={Images.SETTING_ICON}></img>
              <Box sx={{ ml: 2 }}> SETTINGS</Box>
            </MenuItem>
            <MenuItem
              onClick={() => {
                logout();
                setAnchorEl(null);
              }}
            >
              <img src={Images.LOGOUT_ICON}></img>
              <Box sx={{ ml: 2 }}> LOGOUT</Box>
            </MenuItem>
           
          </Menu>
        </>

        // <button>Start</button>
      )}
    </>
  );
};

export default UserInfo;
