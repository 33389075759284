import {
  GET_ALL_COLLECTIONS,
  GET_ALL_COLLECTIONS_SUCCESS,
  GET_USER_COLLECTIONS,
  GET_USER_COLLECTIONS_SUCCESS,
  REGISTER_COLLECTION_REQUEST,
  REGISTER_COLLECTION_SUCCESS,
  GET_ALL_COLLECTIONS_ROWS_LIMIT,
  DASHBOARD_COLLECTION_REQUEST,
  DASHBOARD_COLLECTION_SUCCESS,
  CHANGE_PAGE,
} from "./constants";

export const getAllCollectionsRequest = () => ({
  type: GET_ALL_COLLECTIONS,
 
});

export const getAllCollectionsSuccess = (data: any) => ({
  type: GET_ALL_COLLECTIONS_SUCCESS,
  payload: data,
});
export const getAllCollectionsActiveLimit = (data: any) => ({
  type:  GET_ALL_COLLECTIONS_ROWS_LIMIT,
  payload: data,
});
export const getUserCollection = (data: any) => ({
  type: GET_USER_COLLECTIONS,
  payload: data,
});

export const getUserCollectionSuccess = (data: any) => ({
  type: GET_USER_COLLECTIONS_SUCCESS,
  payload: data,
});

export const registerCollectionRequest = (data: any) => ({
  type: REGISTER_COLLECTION_REQUEST,
  payload: data,
});

export const registerCollectionSuccess = (data: any) => ({
  type: REGISTER_COLLECTION_SUCCESS,
  payload: data,
});


export const dashboardCollectionRequest = (data: any) => ({
  type: DASHBOARD_COLLECTION_REQUEST,
  payload: data,
});

export const dashboardCollectionSuccess = (data: any) => ({
  type: DASHBOARD_COLLECTION_SUCCESS,
  payload: data,
});

export const changePageNumber = (data: any) => ({
  type: CHANGE_PAGE,
  payload: data,
});