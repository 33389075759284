import {
  GET_ALL_REGULAR_BATTLES_REQUEST,
  GET_ALL_REGULAR_BATTLES_SUCCESS,
  GET_ALL_FREE_BATTLE_ROOMS_REQUEST,
  GET_ALL_FREE_BATTLE_ROOMS_SUCCESS,
  REGISTER_NFT_IN_BATTLE_REQUEST,
  REGISTER_NFT_IN_BATTLE_SUCCESS,
  GET_ALL_PENDING_BATTLES_REQUEST,
  GET_ALL_PENDING_BATTLES_SUCCESS,
  GET_BATTLES_HISTORY_REQUEST,
  GET_BATTLES_HISTORY_SUCCESS,
  GET_AVAILABLE_BATTLE_SLOTS_REQUEST,
  GET_AVAILABLE_BATTLE_SLOTS_SUCCESS,
  BATTLE_DETAIL_REQUEST,
  BATTLE_DETAIL_SUCCESS,
  GET_ROOMS_SLOTS_REQUEST,
  GET_ROOMS_SLOTS_SUCCESS,
  FREE_BATTLE_DETAIL_REQUEST,
  FREE_BATTLE_DETAIL_SUCCESS,
  FREE_BATTLE_REGISTER_REQUEST,
  FREE_BATTLE_REGISTER_SUCCESS,
  CHANGE_PAGE,
} from "./constants";

export const getAllRegularBattleRequest = () => ({
  type: GET_ALL_REGULAR_BATTLES_REQUEST,
});

export const getAllRegularBattleSuccess = (data: any) => ({
  type: GET_ALL_REGULAR_BATTLES_SUCCESS,
  payload: data,
});

export const getPendingBattlesRequest = (data: any) => ({
  type: GET_ALL_PENDING_BATTLES_REQUEST,
  payload: data,
});
export const getPendingBattlesSuccess = (data: any) => ({
  type: GET_ALL_PENDING_BATTLES_SUCCESS,
  payload: data,
});
export const getBattleHistoryRequest = (data: any) => ({
  type: GET_BATTLES_HISTORY_REQUEST,
  payload: data,
});
export const getBattleHistorySuccess = (data: any) => ({
  type: GET_BATTLES_HISTORY_SUCCESS,
  payload: data,
});
export const getAllBattleRoomsRequest = () => ({
  type: GET_ALL_FREE_BATTLE_ROOMS_REQUEST,
 
});
export const getAllBattleRoomsSuccess = (data: any) => ({
  type: GET_ALL_FREE_BATTLE_ROOMS_SUCCESS,
  payload: data,
});
export const getRoomSlotsRequest = (data: any) => ({
  type: GET_ROOMS_SLOTS_REQUEST,
  payload: data,
});
export const getRoomSlotsSuccess = (data: any) => ({
  type: GET_ROOMS_SLOTS_SUCCESS,
  payload: data,
});
export const getAvailableSlotsRequest = (data: any) => ({
  type: GET_AVAILABLE_BATTLE_SLOTS_REQUEST,
  payload: data,
});
export const getAvailableSlotsSuccess = (data: any) => ({
  type: GET_AVAILABLE_BATTLE_SLOTS_SUCCESS,
  payload: data,
});
export const registerNftInBattleRequest = (data: any) => ({
  type: REGISTER_NFT_IN_BATTLE_REQUEST,
  payload: data,
});
export const registerNftInBattleSuccess = (data: any) => ({
  type: REGISTER_NFT_IN_BATTLE_SUCCESS,
  payload: data,
});
export const battleDetailRequest = (data: any) => ({
  type: BATTLE_DETAIL_REQUEST,
  payload: data,
});
export const freeBattleDetailRequest = (data: any) => ({
  type: FREE_BATTLE_DETAIL_REQUEST,
  payload: data,
});
export const freeBattleDetailSuccess = (data: any) => ({
  type: FREE_BATTLE_DETAIL_SUCCESS,
  payload: data,
});
export const registerNftInFreeBattleRequest = (data: any) => ({
  type: FREE_BATTLE_REGISTER_REQUEST,
  payload: data,
});
export const registerNftInFreeBattleSuccess = (data: any) => ({
  type: FREE_BATTLE_REGISTER_SUCCESS,
  payload: data,
});
export const battleDetailSuccess = (data: any) => ({
  type: BATTLE_DETAIL_SUCCESS,
  payload: data,
});
export const changePageNumber = (data: any) => ({
  type: CHANGE_PAGE,
  payload: data,
});
