import React from "react";

function home() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.55 0.533441C7.95577 0.191464 8.46935 0.00390625 9 0.00390625C9.53065 0.00390625 10.0442 0.191464 10.45 0.533441L17.2 6.22844C17.708 6.65544 18 7.28444 18 7.94844V17.7504C18 18.2146 17.8156 18.6597 17.4874 18.9879C17.1592 19.3161 16.7141 19.5004 16.25 19.5004H13.25C12.7859 19.5004 12.3408 19.3161 12.0126 18.9879C11.6844 18.6597 11.5 18.2146 11.5 17.7504V12.7504C11.5 12.5515 11.421 12.3608 11.2803 12.2201C11.1397 12.0795 10.9489 12.0004 10.75 12.0004H7.25C7.05109 12.0004 6.86032 12.0795 6.71967 12.2201C6.57902 12.3608 6.5 12.5515 6.5 12.7504V17.7504C6.5 18.2146 6.31563 18.6597 5.98744 18.9879C5.65925 19.3161 5.21413 19.5004 4.75 19.5004H1.75C1.52019 19.5004 1.29262 19.4552 1.0803 19.3672C0.867984 19.2793 0.675066 19.1504 0.512563 18.9879C0.350061 18.8254 0.221157 18.6325 0.133211 18.4201C0.0452652 18.2078 0 17.9803 0 17.7504V7.94744C0 7.28444 0.292 6.65544 0.8 6.22744L7.55 0.533441Z"
        fill="white"
      />
    </svg>
  );
}
export default home;
