import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import Images from "../../../../../shared/constants/AppImages";
import Logo from "../../../../../assets/icon/nftLogo.svg";
import { Link } from "react-router-dom";

// import { useThemeContext } from "../../../../utility/AppContextProvider/ThemeContextProvider";
interface AppLogoProps {
  color?: string;
}

const AppLogo: React.FC<AppLogoProps> = ({ color }) => {
  // const { theme } = useThemeContext();

  return (
    <Box
      sx={{
        height: { xs: 50, sm: 50, lg: 120 },
        width: { xs: 50, sm: 50, lg: 120 },

        padding: 0,
        display: "flex",
        flexDirection: "row",
        // cursor: "pointer",
        alignItems: "center",
        justifyContent: "left",
        "& svg": {
          height: { xs: 20, sm: 25 },
        },
      }}
      className="app-logo"
    >
      <Link to="/home">
        <img src={Images.NFT_LOGO} />
      </Link>
    </Box>
  );
};

export default AppLogo;
