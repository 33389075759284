import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { all, fork, put, select, takeLatest } from "redux-saga/effects";
import axios from "../../routes/axiosConfig";
import { sagaErrorHandler } from "../../shared/helperMethods/sagaErrorHandler";
import { makeSelectAuthToken } from "../store/selectors";
import {
  getNftTraitsRequestSuccess,
  getNftSuccess,
  getSingleCollectionNftsSuccess,
  getSingleNftDetailSuccess,
  upgradeNftTraitsSuccess,
} from "./actions";
import {
  GET_NFT_REQUEST,
  GET_NFT_TRAITS_REQUEST,
  GET_SINGLE_COLLECTION_NFTS_REQUEST,
  GET_SINGLE_NFT_DETAILS_REQUEST,
  UPGRADE_NFT_TRAITS_REQUEST,
} from "./constants";

function* getNftsSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());
  let data = {
    page: payload.page,
    limit: payload.limit,
    collectionId: payload.collectionId,
    walletAddress: payload.walletAddress,
  };
  try {
    const response = yield axios.get(
      `/collection/user-all-nfts-collection?page=${data.page}&limit=${data.limit}&collectionAddress=${data.collectionId}&walletAddress=${data.walletAddress}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(getNftSuccess(response.data.data));
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
  }
}

function* getSingleCollectionNftsSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());
  let data = {
    userId: payload.userId,
    collectionId: payload.collectionId,
  };
  try {
    const response = yield axios.get(
      `/nft/user/${data.userId}/approved-collection/${data.collectionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(getSingleCollectionNftsSuccess(response.data.data));
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
  }
}
function* getSingleNftDetailSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());
  let data = {
    nftId: payload.nftId,
    walletAddress: payload.walletAddress,
    collectionId: payload.collectionId,
  };
  try {
    const response = yield axios.get(
      `/user/nft/blockchain/${data.nftId}?walletAddress=${data.walletAddress}&collectionAddress=${data.collectionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(getSingleNftDetailSuccess(response.data.data));
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
  }
}
function* getNftTraitsSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());

  let data = {
    collectionAddress: payload.collectionId,
    uniqueNftId: payload.nftId,
  };
  try {
    const response = yield axios.get(
      `/nft/approved/unique-nft-id/${payload.nftId}?collectionAddress=${data.collectionAddress}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(getNftTraitsRequestSuccess(response.data.data));
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
  }
}
function* upgradeNftTraitsSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());

  let data = {
    id: payload.traitId,
    value: payload.traitValue,
  };

  try {
    const response = yield axios.put(
      `/nft/approved/unique-nft-id/${payload.nftId}/update-traits?collectionAddress=${payload.collectionId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(upgradeNftTraitsSuccess(response.data.data));
    payload.execCallBack(true);
    toast.success("Trait successfully upgraded");
  } catch (error: any) {
    payload.execCallBack(false);
    yield sagaErrorHandler(error.response);
  }
}

function* watchCollectionNfts() {
  yield takeLatest(
    GET_SINGLE_COLLECTION_NFTS_REQUEST,
    getSingleCollectionNftsSaga
  );
}
function* watchGetNftDetail() {
  yield takeLatest(GET_SINGLE_NFT_DETAILS_REQUEST, getSingleNftDetailSaga);
}
function* watchGetNftTraits() {
  yield takeLatest(GET_NFT_TRAITS_REQUEST, getNftTraitsSaga);
}
function* WatchUpgradeNftTraits() {
  yield takeLatest(UPGRADE_NFT_TRAITS_REQUEST, upgradeNftTraitsSaga);
}
function* WatchNft() {
  yield takeLatest(GET_NFT_REQUEST, getNftsSaga);
}
export default function* nftSaga() {
  yield all([
    fork(watchCollectionNfts),
    fork(WatchUpgradeNftTraits),
    fork(watchGetNftTraits),
    fork(watchGetNftDetail),
    fork(WatchNft),
  ]);
}
