import { combineReducers  } from 'redux';
import { connectRouter } from 'connected-react-router';

import Settings from "../settings/reducers";
import Common from "../common/reducers";
import Auth from "../auth/reducers";
import Collections from "../collections/reducers";
import Nfts from "../nfts/reducers";
import Battles from "../battles/reducers";
import LeaderBoard from "../leaderBoard/reducers";
import Notifications from "../notifications/reducers";
const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: Auth,
  settings: Settings,
  common: Common,
  collections: Collections,
  nfts:Nfts,
  battles:Battles,
  leaderBoard:LeaderBoard,
  notifications: Notifications,
});

export default rootReducer as any;
