import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { all, fork, put, select, takeLatest } from "redux-saga/effects";
import axios from "../../routes/axiosConfig";
import { sagaErrorHandler } from "../../shared/helperMethods/sagaErrorHandler";
import { makeSelectAuthToken } from "../store/selectors";
import {
  battleDetailSuccess,
  freeBattleDetailSuccess,
  getAllBattleRoomsSuccess,
  getAllRegularBattleSuccess,
  getAvailableSlotsSuccess,
  getBattleHistorySuccess,
  getPendingBattlesSuccess,
  getRoomSlotsSuccess,
} from "./actions";
import {
  BATTLE_DETAIL_REQUEST,
  FREE_BATTLE_DETAIL_REQUEST,
  FREE_BATTLE_REGISTER_REQUEST,
  GET_ALL_FREE_BATTLE_ROOMS_REQUEST,
  GET_ALL_PENDING_BATTLES_REQUEST,
  GET_ALL_REGULAR_BATTLES_REQUEST,
  GET_AVAILABLE_BATTLE_SLOTS_REQUEST,
  GET_BATTLES_HISTORY_REQUEST,
  GET_ROOMS_SLOTS_REQUEST,
  REGISTER_NFT_IN_BATTLE_REQUEST,
} from "./constants";

function* getRegularBattlesSaga(): any {
  const token = yield select(makeSelectAuthToken());

  try {
    const response = yield axios.get(`/battle/all/regular-battle?limit=12`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(getAllRegularBattleSuccess(response.data.data));
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
  }
}
function* getAvailableSlotsSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());

  try {
    const response = yield axios.get(`/slot/get-available-slots?filter=true&collectionAddress=${payload.collectionAddress}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(getAvailableSlotsSuccess(response.data.data));
  } catch (error: any) {
    // yield sagaErrorHandler(error.response);
  }
}
function* getPendingBattlesSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());
  let data = {
    userId: payload.userId,
    collectionId: payload.collectionId,
  };
  try {
    const response = yield axios.get(
      `/battle/user/${data.userId}/regular-battle-pending?collectionAddress=${data.collectionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(getPendingBattlesSuccess(response.data.data));
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
  }
}
function* getBattleHistorySaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());
  let data = {
    userId: payload.userId,
    limit: payload.limit,
    page: payload.page,
  };
  try {
    const response = yield axios.get(
      `/battle/user/${data.userId}/regular-battle-finished?limit=${data.limit}&page=${data.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(getBattleHistorySuccess(response.data.data));
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
  }
}
function* getFreeBattleRoomsSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());

  try {
    const response = yield axios.get(`/free-battle/rooms`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(getAllBattleRoomsSuccess(response.data.data));
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
  }
}
function* getRoomSlotsSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());
  let data = {
    roomId: payload.roomId,
  };

  try {
    const response = yield axios.get(
      `/free-battle/all?paginated=0&roomId=${data.roomId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(getRoomSlotsSuccess(response.data.data));
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
  }
}
function* registerNftInBattleSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());

  let data = {
    nftId: payload.nftId,
    collectionAddress: payload.collectionId,
    walletAddress: payload.walletAddress,
    slot: payload.slot,
  };

  try {
    const response = yield axios.post(`/battle/create/regular-battle`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success("Nft successfully registered in battle");
    payload.execCallBack(
      true,
      response.data.data.battle.startTime,
      response.data.data.battle.endTime
    );
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
    payload.execCallBack(
      false,null,null
    );
  }
}
function* registerNftInFreeBattleSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());

  let data = {
    nftId: payload.nftId,
    collectionAddress: payload.collectionId,
    walletAddress: payload.walletAddress,
    slot: payload.slot,
    roomId: payload.roomId,
  };

  try {
    const response = yield axios.post(`/free-battle/create`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success("Nft successfully registered in free Battle");
    payload.execCallBack(
      true,
      response.data.data.battle.startTime,
      response.data.data.battle.endTime
    );
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
    payload.execCallBack(
      false,null,null
    );
  }
}
function* getBattleDetailSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());
  try {
    const response = yield axios.get(
      `/battle/regular/${payload.id}/details?limit=${payload.limit}&page=${payload.page}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(battleDetailSuccess(response.data.data));
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
  }
}
function* getFreeBattleDetailSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());

  try {
    const response = yield axios.get(
      `/free-battle/${payload.id}/details?limit=${payload.limit}&page=${payload.page}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(freeBattleDetailSuccess(response.data.data));
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchRegularBattles() {
  yield takeLatest(GET_ALL_REGULAR_BATTLES_REQUEST, getRegularBattlesSaga);
}
function* watchAvailableSlots() {
  yield takeLatest(GET_AVAILABLE_BATTLE_SLOTS_REQUEST, getAvailableSlotsSaga);
}
function* watchPendingBattles() {
  yield takeLatest(GET_ALL_PENDING_BATTLES_REQUEST, getPendingBattlesSaga);
}
function* watchBattleHistory() {
  yield takeLatest(GET_BATTLES_HISTORY_REQUEST, getBattleHistorySaga);
}
function* watchFreeBattleRooms() {
  yield takeLatest(GET_ALL_FREE_BATTLE_ROOMS_REQUEST, getFreeBattleRoomsSaga);
}
function* watchRoomSlots() {
  yield takeLatest(GET_ROOMS_SLOTS_REQUEST, getRoomSlotsSaga);
}
function* WatchRegisterNftInBattle() {
  yield takeLatest(REGISTER_NFT_IN_BATTLE_REQUEST, registerNftInBattleSaga);
}
function* WatchRegisterNftInFreeBattle() {
  yield takeLatest(FREE_BATTLE_REGISTER_REQUEST, registerNftInFreeBattleSaga);
}
function* watchBattleDetails() {
  yield takeLatest(BATTLE_DETAIL_REQUEST, getBattleDetailSaga);
}
function* watchFreeBattleDetails() {
  yield takeLatest(FREE_BATTLE_DETAIL_REQUEST, getFreeBattleDetailSaga);
}
export default function* battleSaga() {
  yield all([
    fork(watchRegularBattles),
    fork(watchFreeBattleRooms),
    fork(watchRoomSlots),
    fork(WatchRegisterNftInBattle),
    fork(watchPendingBattles),
    fork(watchBattleHistory),
    fork(watchAvailableSlots),
    fork(watchBattleDetails),
    fork(WatchRegisterNftInFreeBattle),
    fork(watchFreeBattleDetails),
  ]);
}
