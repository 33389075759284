import produce from "immer";
import {
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_UNREAD_NOTIFICATION_SUCCESS,
  CHANGE_PAGE,
  NOTIFICATION_COUNT,
} from "./constants";

const initialState = {
  notifications: [],
  unreadNotifications: [],
  pending: false,
  activePage: 1,
  count:0,


};

const Notifications = produce((state = initialState, action: any) => {
  switch (action.type) {
    case GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        pending: true,
      };
      break;
    case GET_NOTIFICATION_SUCCESS:
      state.notifications = action.payload;
      state.pending = false;
      break;
      case GET_UNREAD_NOTIFICATION_SUCCESS:
        state.unreadNotifications = action.payload;
        state.pending = false;
        break;
      case CHANGE_PAGE:
      state.activePage = action.payload;
      break;
      case NOTIFICATION_COUNT:
      state.count = action.payload;
      break;
    default:
  }
}, initialState);

export default Notifications;
