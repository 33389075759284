import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { all, fork, put, select, takeLatest } from "redux-saga/effects";
import axios from "../../routes/axiosConfig";
import { sagaErrorHandler } from "../../shared/helperMethods/sagaErrorHandler";
import { makeSelectAuthToken } from "../store/selectors";
import {
  connectRequestSuccess, depositSuccess, getUserSuccess, setLoader, sign1RequestSuccess,
  sign2RequestSuccess, updateProfileSuccess,
  withdrawSuccess
} from "./actions";
import { CONNECT, DEPOSIT_REQUEST, GET_USER_REQUEST, SIGN1, SIGN2, UPDATE_PROFILE, WITHDRAW_REQUEST } from "./constants";

// Login Saga //

function* connectRequestSaga({ payload }: any): any {
  let data = {
    walletAddress: payload.walletAddress,
    // transactionsWalletAddress:"0x2F075618681D45458aE20E17ca3CCf1C797d6E1a"
  };
  try {
    const response = yield axios.post(`user/login`, data);
    yield put(connectRequestSuccess(response.data.data));
  } catch (error: any) {
    // yield sagaErrorHandler(error.response);
  }
}

function* sign1RequestSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());
  let data = {
    hash: payload.hash,
    walletAddress: payload.walletAddress
  };
  try {
    const response = yield axios.post(`/user/second-step-login`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(sign1RequestSuccess(response.data.data));
  } catch (error: any) {
    // yield sagaErrorHandler(error.response);
  }
}

function* sign2RequestSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());
  let data = {
    hash: payload.hash,
    walletAddress: payload.walletAddress
  };
  try {
    const response = yield axios.post(`/user/third-step-login`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(sign2RequestSuccess(response.data.data));
    yield put(setLoader(false));
    // yield put(push("/home"))
  } catch (error: any) {
    // yield sagaErrorHandler(error.response);
  }
}

function* updateProfileRequestSaga({payload}: any): any {

  try {
    const response = yield axios.put(
      `user/update/wallet-address/${payload.auth.walletAddress}`,
      payload.data
    );
    yield put(updateProfileSuccess(response.data.data));
    toast.success(response.data.message);
    yield put(setLoader(false));
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
  }
}


function* getUserRequestSaga({payload}:any) :any{
  try {
    const response = yield axios.get(
      `/user/walletAddress/${payload.walletAddress}`,
      payload.payload
    );
    yield put(getUserSuccess(response.data.data));
    // toast.success(response.data.status);
    yield put(setLoader(false));
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
  }
}
function* withdrawRequestSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());

  let data = {
    walletAddress: payload.walletAddress,
    Amount:payload.amount
  };
  try {
    const response = yield axios.post(`/wallet/withdraw`, data,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success(response.data.data.message);
    yield put(withdrawSuccess(response.data.data));
    payload.execCallBack(
      false);
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
    // toast.error("Something went wrong!");
    payload.execCallBack(
      false);
  }
}
function* depositRequestSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());
  let data = {
    txId:payload.txId,
    walletAddress: payload.walletAddress,
    
  };
  try {
    const response = yield axios.post(`/wallet/deposit`, data ,  {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success(response.data.data.message);
    yield put(depositSuccess(response.data.data));
    payload.execCallBack(
      false);
  } catch (error: any) {
    payload.execCallBack(
      false);
    yield sagaErrorHandler(error.response);
  }
}




function* watchConnect() {
  yield takeLatest(CONNECT, connectRequestSaga);
}

function* watchSign1() {
  yield takeLatest(SIGN1, sign1RequestSaga);
}

function* watchSign2() {
  yield takeLatest(SIGN2, sign2RequestSaga);
}

function* watchUpdateProfile() {
  yield takeLatest(UPDATE_PROFILE, updateProfileRequestSaga);
}

function* watchUserData() {
  yield takeLatest(GET_USER_REQUEST, getUserRequestSaga);
}
function* watchWithdraw() {
  yield takeLatest(WITHDRAW_REQUEST, withdrawRequestSaga);
}
function* watchDeposit() {
  yield takeLatest(DEPOSIT_REQUEST, depositRequestSaga);
}

export default function* authSaga() {
  yield all([
    fork(watchConnect),
    fork(watchUpdateProfile),
    fork(watchSign1),
    fork(watchSign2),
    fork(watchWithdraw),
    fork(watchDeposit),
    fork(watchUserData),



    // fork(watchRegister),
    // fork(watchForget),
    // fork(watchReset),
  ]);
}
