import produce from "immer";
import {
  GET_LEADER_BOARD_REQUEST,
  GET_USER_LEADER_BOARD_REQUEST,
  GET_LEADER_BOARD_SUCCESS,
  CHANGE_PAGE,
} from "./constants";

const initialState = {
  leaderBoardData: [],
  pending: false,
  activeLimit: 0,
  activePage: 1,

};

const LeaderBoard = produce((state = initialState, action: any) => {
  switch (action.type) {
    case GET_LEADER_BOARD_REQUEST:
      case GET_USER_LEADER_BOARD_REQUEST:
      return {
        ...state,
        pending: true,
      };
      break;
    case GET_LEADER_BOARD_SUCCESS:
      state.leaderBoardData = action.payload;
      state.pending = false;
      break;

    case CHANGE_PAGE:
      state.activePage = action.payload;
      break;
    default:
  }
}, initialState);

export default LeaderBoard;
