import { toast } from "react-toastify";
import { logoutRequest } from "../../redux/auth/actions";
import { put } from "redux-saga/effects";

export function* sagaErrorHandler(error) {
  if (!error) return;
  if (error) {
    if (error?.data?.data?.message !== "your session is expired. Please login again") {
      toast.error(error?.data?.data?.message);
    } else {
      yield put(logoutRequest());
    }
  } else if (
    error?.data?.message === "Your account has been blocked!" ||
    error?.data?.message === "Your account has not verified yet!" ||
    error?.data?.message === "Email not confirmed"
  ) {
    toast.error(error?.data?.data?.message);
  } else if (error?.data?.data?.message) {
    toast.error(error?.data?.data?.message);
  }
}
