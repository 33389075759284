import Typography from "@mui/material/Typography";
import React from "react";
import { useLayoutContext } from "../../../../utility/AppContextProvider/LayoutContextProvider";
import FooterWrapper from "./FooterWrapper";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import Images from "../../../../../shared/constants/AppImages";

const AppFooter = () => {
  const { footer, footerType, navStyle } = useLayoutContext();

  return (
    <>
      {footer &&
      footerType === "fluid" &&
      navStyle !== "h-default" &&
      navStyle !== "hor-light-nav" &&
      navStyle !== "hor-dark-layout" ? (
        <FooterWrapper className="footer">
          <div className="footerContainer">
            <Typography sx={{color:"rgba(12, 218, 107, 1)",fontWeight:"bold"}}>
              Copy right @BattleTools {new Date().getFullYear()}
            </Typography>
            <Box sx={{ ml: "auto" }}>
             <a href="https://twitter.com/Battle_Tools" target="_blank">
                <img
                  src={Images.TWITTER_ICON}
                  width="20px"
                  height="20px"
                  
                  alt={""}
                  style={{cursor:"pointer",marginRight:"10px"}}
                />
                </a>
                <a href="#">
                <img
                  src={Images.MEDIUM_ICON}
                  width="20px"
                  height="20px"
                  
                  alt={""}
                  style={{cursor:"pointer",marginRight:"10px"}}
                />
                </a>
             <a href="https://discord.gg/mkn4HCzhfD" target="_blank">
                <img
                  src={Images.DISCORD_ICON}
                  width="20px"
                  height="20px"
                  alt={""}
                  style={{cursor:"pointer",marginRight:"5px"}}
                />
              </a>
            </Box>
          </div>
        </FooterWrapper>
      ) : null}
    </>
  );
};

export default AppFooter;
