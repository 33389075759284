import { Box, ListItem, Typography } from "@mui/material";
import React from "react";
import { Fonts } from "../../../shared/constants/AppEnums";
interface NotificationItemProps {
  item: any;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ item }) => {
  return (
    <ListItem
      sx={{
        padding: "8px 20px",
        borderBottom: "1px solid rgba(79, 199, 91, 1)",
      }}
      className="item-hover"
    >
      <Box
        sx={{
          fontSize: 14,
          color: (theme) => theme.palette.text.secondary,
          width: "100%",
        }}
      >
        <Typography>
          <Box
            component="h2"
            sx={{
              fontSize: 14,
              fontWeight: Fonts.REGULAR,
              mb: 1,

              // mr: 1,
              display: "inline-block",
            }}
          >
            Battle Type
          </Box>
          <Box
            component="h2"
            sx={{
              fontSize: 14,
              fontWeight: Fonts.BOLD,
              mb: 1,
              float: "right",
              ml: "auto",
              // mr: 1,
              display: "inline-block",
            }}
          >
            <span style={{ color: "rgba(79, 199, 91, 1)" }}>
              {" "}
              {item?.battleType ? item?.battleType : "----"}
            </span>
          </Box>
          <Typography>
            <Box
              component="h2"
              sx={{
                fontSize: 14,
                fontWeight: Fonts.REGULAR,
                mb: 1,

                // mr: 1,
                display: "inline-block",
              }}
            >
              NFT Id
            </Box>
            <Box
              component="h2"
              sx={{
                fontSize: 14,
                fontWeight: Fonts.BOLD,
                mb: 1,
                float: "right",
                ml: "auto",
                // mr: 1,
                display: "inline-block",
              }}
            >
              <span style={{ color: "rgba(79, 199, 91, 1)" }}>
                {" "}
                {item?.NftId ? item?.NftId : "----"}
              </span>
            </Box>
          </Typography>
          <Typography>
            <Box
              component="h2"
              sx={{
                fontSize: 14,
                fontWeight: Fonts.REGULAR,
                mb: 1,

                // mr: 1,
                display: "inline-block",
              }}
            >
              Time
            </Box>
            <Box
              component="h2"
              sx={{
                fontSize: 14,
                fontWeight: Fonts.BOLD,
                mb: 1,
                float: "right",
                ml: "auto",
                // mr: 1,
                display: "inline-block",
              }}
            >
              <span style={{ color: "rgba(79, 199, 91, 1)" }}>
                {" "}
                UTC {item?.startTime} - UTC {item?.endTime}
              </span>
            </Box>
          </Typography>
        </Typography>
        <Box
          component="h2"
          sx={{
            fontSize: 14,
            fontWeight: Fonts.REGULAR,
            mb: 1,

            // mr: 1,
            display: "inline-block",
          }}
        >
          Result
        </Box>
        <Box
          component="h2"
          sx={{
            fontSize: 14,
            fontWeight: Fonts.BOLD,
            mb: 1,
            float: "right",
            ml: "auto",
            // mr: 1,
            display: "inline-block",
          }}
        >
          <span style={{ color: "rgba(79, 199, 91, 1)" }}>
            {" "}
            {item?.result ? item?.result : "----"}
          </span>
        </Box>
      </Box>
    </ListItem>
  );
};

export default NotificationItem;
