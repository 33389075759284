import { ReactNode } from "react";
import { RoutePermittedRole } from "../shared/constants/AppConst";
import Images from "../shared/constants/AppImages";
// import Home from "../../src/assets/icon/home.svg";
import Home from "../../src/assets/icon/homeIcon";
export interface RouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: any;
  type: "item" | "group" | "collapse" | "divider";
  children?: RouterConfigData[];
  permittedRole?: RoutePermittedRole;
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
  display?: boolean;
}

const routesConfig: RouterConfigData[] = [
  {
    id: "app",
    title: "Home",
    messageId: "nav.home",
    type: "item",
    url: "/home",
    display: true,
    icon: <Home />,
  },
  {
    id: "nft",
    title: "my nft",
    messageId: "nav.myNft",
    type: "item",
    url: "/my-nfts",
    display: false,
    icon: (
      <svg
      width="20"
      height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H18C19.1 20 20 19.1 20 18V2C20 0.9 19.1 0 18 0ZM9 18H2V11H9V18ZM9 9H2V2H9V9ZM18 18H11V11H18V18ZM18 9H11V2H18V9Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    id: "battles",
    title: "Battles",
    messageId: "nav.battle",
    type: "item",
    url: "/battles",
    display: true,
    icon: (
      <svg
      width="20"
      height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.0012 3.10598C20.0012 3.04698 20.0012 2.91898 20.0642 2.91898C20.1542 2.91898 20.2312 3.10898 20.2602 3.18898C20.7462 4.73698 20.6102 6.63798 19.9152 8.77698C22.6712 10.25 24.2532 12.159 23.9672 13.945C23.5872 16.292 20.1432 17.672 15.5052 17.51C16.0484 16.9248 16.5656 16.3158 17.0552 15.685C18.8412 15.338 20.1212 14.632 20.4552 13.501C20.7012 12.678 20.1352 11.765 19.0592 10.907C17.2912 14.63 14.3372 17.965 11.6082 19.892C12.9312 21.579 14.1642 22.75 15.5722 23.408C15.6242 23.433 15.7362 23.498 15.6982 23.556C15.6542 23.634 15.4462 23.606 15.3682 23.59C13.7832 23.238 12.2102 22.17 10.6952 20.497C8.04315 22.147 5.59615 22.563 4.19315 21.422C2.35115 19.922 2.87715 16.244 5.33015 12.316C5.52315 12.947 5.83515 13.842 6.13615 14.569C5.54215 16.29 5.51415 17.752 6.32615 18.61C6.91615 19.233 7.99015 19.2 9.27115 18.698C6.94615 15.303 5.53315 11.078 5.23115 7.74398C3.10715 8.04698 1.47715 8.52898 0.204152 9.41898C0.156152 9.45198 0.0441523 9.51698 0.00915227 9.45898C-0.0348477 9.38098 0.0911523 9.21898 0.146152 9.15498C1.24415 7.95898 2.95815 7.12698 5.15815 6.65898C5.05615 3.53498 5.91615 1.20898 7.60815 0.565979C9.82815 -0.280021 12.7482 2.01398 14.9242 6.10798C14.1463 5.92976 13.3609 5.78662 12.5702 5.67898C11.3772 4.30698 10.1242 3.54898 8.97615 3.82498C8.14215 4.02498 7.63515 4.97098 7.42915 6.32698C11.5372 5.99498 15.9022 6.88698 18.9392 8.29098C19.7392 6.30698 20.1362 4.65398 20.0012 3.10598ZM10.4032 18.163C13.6552 16.304 16.2922 13.467 18.0412 10.198C14.8012 8.31798 11.0282 7.44798 7.32515 7.57098C7.31715 11.316 8.44915 15.022 10.4072 18.165L10.4032 18.163Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    id: "leader_board",
    title: "Leader Board",
    messageId: "nav.leaderBoard",
    type: "item",
    url: "/leader-board",
    display: true,
    icon: (
      <svg
      width="20"
      height="20"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.5 18H1C0.45 18 0 17.55 0 17V7C0 6.45 0.45 6 1 6H4.5C5.05 6 5.5 6.45 5.5 7V17C5.5 17.55 5.05 18 4.5 18ZM11.75 0H8.25C7.7 0 7.25 0.45 7.25 1V17C7.25 17.55 7.7 18 8.25 18H11.75C12.3 18 12.75 17.55 12.75 17V1C12.75 0.45 12.3 0 11.75 0ZM19 8H15.5C14.95 8 14.5 8.45 14.5 9V17C14.5 17.55 14.95 18 15.5 18H19C19.55 18 20 17.55 20 17V9C20 8.45 19.55 8 19 8Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    id: "register_collection",
    title: "Register Collection",
    messageId: "nav.registerCollection",
    type: "item",
    url: "/register-collection",
    display: true,
    icon: (
      <svg
      width="20"
      height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 17.0625C0 17.5846 0.207421 18.0854 0.576634 18.4546C0.945846 18.8238 1.44661 19.0312 1.96875 19.0312H19.0312C19.5534 19.0312 20.0542 18.8238 20.4234 18.4546C20.7926 18.0854 21 17.5846 21 17.0625V7.875C21 7.35286 20.7926 6.8521 20.4234 6.48288C20.0542 6.11367 19.5534 5.90625 19.0312 5.90625H1.96875C1.44661 5.90625 0.945846 6.11367 0.576634 6.48288C0.207421 6.8521 0 7.35286 0 7.875L0 17.0625ZM2.625 3.9375C2.625 4.11155 2.69414 4.27847 2.81721 4.40154C2.94028 4.52461 3.1072 4.59375 3.28125 4.59375H17.7188C17.8928 4.59375 18.0597 4.52461 18.1828 4.40154C18.3059 4.27847 18.375 4.11155 18.375 3.9375C18.375 3.76345 18.3059 3.59653 18.1828 3.47346C18.0597 3.35039 17.8928 3.28125 17.7188 3.28125H3.28125C3.1072 3.28125 2.94028 3.35039 2.81721 3.47346C2.69414 3.59653 2.625 3.76345 2.625 3.9375ZM5.25 1.3125C5.25 1.48655 5.31914 1.65347 5.44221 1.77654C5.56528 1.89961 5.7322 1.96875 5.90625 1.96875H15.0938C15.2678 1.96875 15.4347 1.89961 15.5578 1.77654C15.6809 1.65347 15.75 1.48655 15.75 1.3125C15.75 1.13845 15.6809 0.971532 15.5578 0.848461C15.4347 0.72539 15.2678 0.65625 15.0938 0.65625H5.90625C5.7322 0.65625 5.56528 0.72539 5.44221 0.848461C5.31914 0.971532 5.25 1.13845 5.25 1.3125Z"
          fill="white"
        />
      </svg>
    ),
  },
];
export default routesConfig;
