import {
  LOGOUT,
  CONNECT,
  CONNECT_SUCCESS,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  SET_LOADER,
  SIGN1,
  SIGN1_SUCCESS,
  SIGN2,
  SIGN2_SUCCESS,
  WITHDRAW_REQUEST,
  WITHDRAW_SUCCESS,
  DEPOSIT_REQUEST,
  DEPOSIT_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,

} from "./constants";

export const logoutRequest = () => ({
  type: LOGOUT,
});

export const connectRequest = (data: any) => ({
  type: CONNECT,
  payload: data,
});

export const connectRequestSuccess = (data: any) => ({
  type: CONNECT_SUCCESS,
  payload: data,
});

export const sign1Request = (data: any) => ({
  type: SIGN1,
  payload: data,
});

export const sign1RequestSuccess = (data: any) => ({
  type: SIGN1_SUCCESS,
  payload: data,
});

export const sign2Request = (data: any) => ({
  type: SIGN2,
  payload: data,
});

export const sign2RequestSuccess = (data: any) => ({
  type: SIGN2_SUCCESS,
  payload: data,
});

export const forgotPassword = (data: any) => {
  return {
    type: FORGOT_PASSWORD,
    payload: data,
  };
};
export const resetPassword = (data: any) => {
  return {
    type: RESET_PASSWORD,
    payload: data
  };
};

export const updateProfileRequest = (data: any) => ({
  type: UPDATE_PROFILE,
  payload: data,
});

export const updateProfileSuccess = (data: any) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: data,
});
export const getUserRequest = (data: any) => ({
  type: GET_USER_REQUEST,
  payload: data,
});
export const getUserSuccess = (data: any) => ({
  type: GET_USER_SUCCESS,
  payload: data,
});
export const setLoader = (data: any) => {
  return {
    type: SET_LOADER,
    payload: data
  };
};

export const withdrawRequest = (data: any) => ({
  type: WITHDRAW_REQUEST,
  payload: data,
});
export const withdrawSuccess = (data: any) => ({
  type: WITHDRAW_SUCCESS,
  payload: data,
});

export const depositRequest = (data: any) => ({
  type: DEPOSIT_REQUEST,
  payload: data,
});
export const depositSuccess = (data: any) => ({
  type: DEPOSIT_SUCCESS,
  payload: data,
});


