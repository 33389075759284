import { all, fork, put, select, takeLatest } from "redux-saga/effects";
import axios from "../../routes/axiosConfig";
import { sagaErrorHandler } from "../../shared/helperMethods/sagaErrorHandler";
import { makeSelectAuthToken } from "../store/selectors";
import { getLeaderBoardSuccess } from "./actions";
import { GET_LEADER_BOARD_REQUEST ,GET_USER_LEADER_BOARD_REQUEST} from "./constants";

// leaderboard Saga //

function* getLeaderBoardSaga({ payload }: any): any {
  // const token = yield select(makeSelectAuthToken());

  try {
    const response = yield axios.get(`/battle/get-leader-board?user=false&limit=${payload.limit}&page=${payload.page}&collectionAddress=${payload.collectionAddress}`);

    // toast.success("Collection Successfully Registered")

    yield put(getLeaderBoardSuccess(response.data.data));
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
  }
}
function* getUserLeaderBoardSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());

  try {
    const response = yield axios.get(`/battle/user-leader-board?limit=${payload.limit}&page=${payload.page}&collectionAddress=${payload.collectionAddress}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // toast.success("Collection Successfully Registered")

    yield put(getLeaderBoardSuccess(response.data.data));
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
  }
}

function* watchLeaderBoard() {
  yield takeLatest(GET_LEADER_BOARD_REQUEST, getLeaderBoardSaga);
}
function* watchUserLeaderBoard() {
  yield takeLatest(GET_USER_LEADER_BOARD_REQUEST, getUserLeaderBoardSaga);
}

export default function* leaderBoardSaga() {
  yield all([fork(watchLeaderBoard)]);
  yield all([fork(watchUserLeaderBoard)]);
}
