import {
  GET_SINGLE_COLLECTION_NFTS_REQUEST,
  GET_SINGLE_COLLECTION_NFTS_SUCCESS,
  GET_NFT_TRAITS_REQUEST,
  GET_NFT_TRAITS_SUCCESS,
  UPGRADE_NFT_TRAITS_REQUEST,
  UPGRADE_NFT_TRAITS_SUCCESS,
  GET_SINGLE_NFT_DETAILS_REQUEST,
  GET_SINGLE_NFT_DETAILS_SUCCESS,
  GET_NFT_REQUEST,
  GET_NFT_SUCCESS,
  CHANGE_PAGE
} from "./constants";

export const getSingleCollectionNftsRequest = (data: any) => ({
  type: GET_SINGLE_COLLECTION_NFTS_REQUEST,
  payload: data,
});

export const getSingleCollectionNftsSuccess = (data: any) => ({
  type: GET_SINGLE_COLLECTION_NFTS_SUCCESS,
  payload: data,
});
export const getSingleNftDetailRequest = (data: any) => ({
  type: GET_SINGLE_NFT_DETAILS_REQUEST,
  payload: data,
});
export const getSingleNftDetailSuccess = (data: any) => ({
  type: GET_SINGLE_NFT_DETAILS_SUCCESS,
  payload: data,
});
export const getNftTraitsRequest = (data: any) => ({
  type: GET_NFT_TRAITS_REQUEST,
  payload: data,
});

export const getNftTraitsRequestSuccess = (data: any) => ({
  type: GET_NFT_TRAITS_SUCCESS,
  payload: data,
});

export const upgradeNftTraitsRequest = (data: any) => ({
  type: UPGRADE_NFT_TRAITS_REQUEST,
  payload: data,
});

export const upgradeNftTraitsSuccess = (data: any) => ({
  type: UPGRADE_NFT_TRAITS_SUCCESS,
  payload: data,
});
export const getNftRequest = (data: any) => ({
  type: GET_NFT_REQUEST,
  payload: data,
});
export const getNftSuccess = (data: any) => ({
  type: GET_NFT_SUCCESS,
  payload: data,
});
export const changePageNumber = (data: any) => ({
  type: CHANGE_PAGE,
  payload: data,
});
