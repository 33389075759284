import { all } from 'redux-saga/effects';
import authSaga from '../auth/sagas';
import battleSaga from '../battles/sagas';
import collectionSaga from '../collections/sagas';
import nftSaga from '../nfts/sagas';
import leaderBoardSaga from '../leaderBoard/sagas';
import notificationSaga from "../notifications/sagas";
export function* rootSaga() {
    yield all([
        authSaga(),
        collectionSaga(),
        nftSaga(),
        battleSaga(),
        leaderBoardSaga(),
        notificationSaga(),
    ])
}