export const GET_ALL_REGULAR_BATTLES_REQUEST="get_all_regular_battles_request";
export const GET_ALL_REGULAR_BATTLES_SUCCESS="get_all_regular_battles_success";

export const GET_ALL_PENDING_BATTLES_REQUEST="get_all_pending_battles_request";
export const GET_ALL_PENDING_BATTLES_SUCCESS="get_all_pending_battles_success";

export const GET_BATTLES_HISTORY_REQUEST="get_battle_history_request";
export const GET_BATTLES_HISTORY_SUCCESS="get_battle_history_success";

export const BATTLE_DETAIL_REQUEST="battle_detail_request";
export const BATTLE_DETAIL_SUCCESS="battle_detail_success";

export const REGISTER_NFT_IN_BATTLE_REQUEST="register_nft_in_battle_request";
export const REGISTER_NFT_IN_BATTLE_SUCCESS="register_nft_in_battle_success";

export const GET_AVAILABLE_BATTLE_SLOTS_REQUEST="get_available_battle_slots_request";
export const GET_AVAILABLE_BATTLE_SLOTS_SUCCESS="get_available_battle_slots_success";



export const GET_ALL_FREE_BATTLE_ROOMS_REQUEST="get_all_free_battle_rooms_request";
export const GET_ALL_FREE_BATTLE_ROOMS_SUCCESS="get_all_free_battle_rooms_success";

export const GET_ROOMS_SLOTS_REQUEST="get_rooms_slots_request";
export const GET_ROOMS_SLOTS_SUCCESS="get_rooms_slots_success";


export const FREE_BATTLE_DETAIL_REQUEST="free_battle_detail_request";
export const FREE_BATTLE_DETAIL_SUCCESS="free_battle_detail_success";

export const FREE_BATTLE_REGISTER_REQUEST="free_battle_register_request";
export const FREE_BATTLE_REGISTER_SUCCESS="free_battle_register_success";


export const CHANGE_PAGE = "change_page";








