import produce from "immer";
import {
  GET_NFT_TRAITS_SUCCESS,
  GET_SINGLE_COLLECTION_NFTS_SUCCESS,
  UPGRADE_NFT_TRAITS_SUCCESS,
  CHANGE_PAGE,
  GET_NFT_TRAITS_REQUEST,
  GET_SINGLE_NFT_DETAILS_REQUEST,
  GET_SINGLE_NFT_DETAILS_SUCCESS,
  GET_NFT_REQUEST,
  GET_NFT_SUCCESS,
} from "./constants";

const initialState = {
  collectionNfts: [],
  nftTraits: [],
  nftDetails: [],
  getNft:[],
  activePage: 0,
  pending: false,
  isLoading:false,
};

const Nfts = produce((state = initialState, action: any) => {
  switch (action.type) {
    case GET_NFT_TRAITS_REQUEST:
      case GET_SINGLE_NFT_DETAILS_REQUEST:
        
      return {
        ...state,
        pending: true,
      };
      case GET_NFT_REQUEST: return {
        ...state,
        isLoading: true,
      };
    case GET_SINGLE_COLLECTION_NFTS_SUCCESS:
      state.collectionNfts = action.payload.nfts;
      state.pending = false;

      break;
    case GET_NFT_TRAITS_SUCCESS:
      state.nftTraits = action.payload.nft.traits;
      state.pending = false;
      break;
      case UPGRADE_NFT_TRAITS_SUCCESS:
        state.nftTraits = action.payload.updatedTraits;
        state.pending = false;
        break;
        case GET_SINGLE_NFT_DETAILS_SUCCESS:
          state.nftDetails = action.payload;
          state.pending = false;
          break;
          case GET_NFT_SUCCESS:
            state.getNft = action.payload;
            state.isLoading = false;
            break;
    case CHANGE_PAGE:
      state.activePage = action.payload;
      break;

    default:
  }
}, initialState);

export default Nfts;
