import produce from "immer";
import {
  GET_ALL_COLLECTIONS_SUCCESS,
  GET_USER_COLLECTIONS_SUCCESS,
  GET_ALL_COLLECTIONS_ROWS_LIMIT,
  DASHBOARD_COLLECTION_REQUEST,
  DASHBOARD_COLLECTION_SUCCESS,
  CHANGE_PAGE,
} from "./constants";

const initialState = {
  allCollections: [],
  userCollections: [],
  homePageCollections:[],
  activeLimit: 0,
  activePage: 1,
  pending: false,

};

const Collections = produce((state = initialState, action: any) => {



  switch (action.type) {

    case DASHBOARD_COLLECTION_REQUEST:
      return {
        ...state,
        pending: true,
      };
      break;
    case GET_ALL_COLLECTIONS_SUCCESS:
      state.allCollections = action.payload;

      break;
    case GET_USER_COLLECTIONS_SUCCESS:
      state.userCollections = action.payload;
      break;
    case GET_ALL_COLLECTIONS_ROWS_LIMIT:
      state.activeLimit = action.payload.rows;
      break;
      case DASHBOARD_COLLECTION_SUCCESS:
        state.homePageCollections = action.payload;
        state.pending = false;
        break;
        case CHANGE_PAGE:
          state.activePage = action.payload;
          break;
    default:
  }
}, initialState);

export default Collections;
