import produce from "immer";
import {
  GET_ALL_REGULAR_BATTLES_REQUEST,
  GET_ALL_REGULAR_BATTLES_SUCCESS,
  GET_ALL_FREE_BATTLE_ROOMS_REQUEST,
  GET_ALL_FREE_BATTLE_ROOMS_SUCCESS,
  GET_ALL_PENDING_BATTLES_REQUEST,
  GET_ALL_PENDING_BATTLES_SUCCESS,
  GET_AVAILABLE_BATTLE_SLOTS_SUCCESS,
  GET_BATTLES_HISTORY_REQUEST,
  GET_BATTLES_HISTORY_SUCCESS,
  BATTLE_DETAIL_REQUEST,
  BATTLE_DETAIL_SUCCESS,
  GET_ROOMS_SLOTS_REQUEST,
  GET_ROOMS_SLOTS_SUCCESS,
  FREE_BATTLE_DETAIL_REQUEST,
  FREE_BATTLE_DETAIL_SUCCESS,
  CHANGE_PAGE,
} from "./constants";

const initialState = {
  regularBattles: [],
  freeBattleRooms: [],
  battleRoomSlots: [],
  pendingEvents: [],
  battlesHistory: [],
  availableSlots: [],
  battleDetail: [],
  activePage: 1,
  pending: false,
};

const Battles = produce((state = initialState, action: any) => {
  switch (action.type) {
    case GET_ALL_FREE_BATTLE_ROOMS_REQUEST:
    case GET_ALL_REGULAR_BATTLES_REQUEST:
    case GET_ALL_PENDING_BATTLES_REQUEST:
    case GET_BATTLES_HISTORY_REQUEST:
    case GET_ROOMS_SLOTS_REQUEST:
    case FREE_BATTLE_DETAIL_REQUEST:
    case BATTLE_DETAIL_REQUEST:
      return {
        ...state,
        pending: true,
      };
      break;
    case GET_ALL_REGULAR_BATTLES_SUCCESS:
      state.regularBattles = action.payload.battles;
      state.pending = false;
      break;
    case GET_ALL_FREE_BATTLE_ROOMS_SUCCESS:
      state.freeBattleRooms = action.payload;
      state.pending = false;
      break;
    case GET_ALL_PENDING_BATTLES_SUCCESS:
      state.pendingEvents = action.payload.battles;
      state.pending = false;
      break;
    case GET_BATTLES_HISTORY_SUCCESS:
      state.battlesHistory = action.payload.battles;
      state.pending = false;
      break;
    case GET_AVAILABLE_BATTLE_SLOTS_SUCCESS:
      state.availableSlots = action.payload.timeSlots;
      state.pending = false;
      break;
    case BATTLE_DETAIL_SUCCESS:
      state.battleDetail = action.payload;
      state.pending = false;
      break;
    case FREE_BATTLE_DETAIL_SUCCESS:
      state.battleDetail = action.payload;
      state.pending = false;
      break;
    case GET_ROOMS_SLOTS_SUCCESS:
      state.battleRoomSlots = action.payload;
      state.pending = false;
      break;
    case CHANGE_PAGE:
      state.activePage = action.payload;
      break;

    default:
  }
}, initialState);

export default Battles;
