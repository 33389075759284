import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { Database, getDatabase } from "firebase/database";

// Initialize Firebase



const firebaseConfig = {
  apiKey: "AIzaSyBJGfOXiKETn9xNBDyJ30Z9qkmJyCRE4Qg",
  authDomain: "battle-tools.firebaseapp.com",
  databaseURL: "https://battle-tools-default-rtdb.firebaseio.com",
  projectId: "battle-tools",
  storageBucket: "battle-tools.appspot.com",
  messagingSenderId: "942148253458",
  appId: "1:942148253458:web:590e7fb26c0de915e7a5cb",
  measurementId: "G-5SQBCB93P5"
};

const app = firebase.initializeApp(firebaseConfig);
const  firebaseDB:Database = getDatabase(app);
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

export {
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  firebaseDB,
};
