import {
  GET_LEADER_BOARD_REQUEST,
  GET_LEADER_BOARD_SUCCESS,
  GET_USER_LEADER_BOARD_REQUEST,
  CHANGE_PAGE,
} from "./constants";

export const getLeaderBoardRequest = (data: any) => ({
  type:  GET_LEADER_BOARD_REQUEST,
  payload: data,
});

export const getLeaderBoardSuccess = (data: any) => ({
  type: GET_LEADER_BOARD_SUCCESS,
  payload: data,
});
export const getUserLeaderBoardRequest = (data: any) => ({
  type:  GET_USER_LEADER_BOARD_REQUEST,
  payload: data,
});
export const changePageNumber = (data: any) => ({
  type: CHANGE_PAGE,
  payload: data,
});





