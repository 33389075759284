import AppScrollbar from "@crema/core/AppScrollbar";
import IntlMessages from "@crema/utility/IntlMessages";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { CircularProgress, IconButton, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  changePageNumber,
  getNotificationsRequest,
} from "../../../redux/notifications/actions";
import Images from "../../../shared/constants/AppImages";
import NotificationItem from "./NotificationItem";

interface AppNotificationContentProps {
  onClose: () => void;
  sxStyle: SxProps<Theme>;
}

const AppNotificationContent: React.FC<AppNotificationContentProps> = ({
  onClose,
  sxStyle,
}) => {
  const dispatch = useDispatch();

  const history: any = useHistory();
  const isLoading: any = useSelector(
    (state: any) => state?.notifications?.pending
  );
  const NotificationData: any = useSelector(
    (state: any) =>
      state?.notifications?.unreadNotifications?.notifications?.rows
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 280,
        backgroundColor: "rgba(53, 53, 53, 1)",
        height: "100%",
        borderTopLeftRadius: "40px",
        borderBottomLeftRadius: "40px",
        ...sxStyle,
      }}
    >
      <Box
        sx={{
          padding: "5px 20px",
          display: "flex",
          alignItems: "center",

          // borderRadius:"100px",
          borderTopLeftRadius: "40px",
          // backgroundColor:"red",
          borderBottom: 1,
          borderBottomColor: "rgba(79, 199, 91, 1)",
          minHeight: { xs: 56, sm: 70 },
        }}
      >
        <Typography component="h2" sx={{ color: "white", fontWeight: "400" }}>
          <IntlMessages id="common.notifications" />({NotificationData?.length})
        </Typography>
        <IconButton
          sx={{
            height: 40,
            width: 40,
            marginLeft: "auto",
            color: "text.secondary",
          }}
          onClick={onClose}
          size="large"
        >
          <CancelOutlinedIcon />
        </IconButton>
      </Box>
      <AppScrollbar
        sx={{
          height: { xs: "calc(100% - 46px)", sm: "calc(100% - 110px)" },
        }}
      >
        <List sx={{ py: 0 }}>
          {isLoading ? (
            <div
              style={{
                marginTop: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : NotificationData?.length > 0 ? (
            NotificationData?.map((item) => (
              <NotificationItem key={item.id} item={item} />
            ))
          ) : (
            <div
              style={{
                marginTop: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Images.NO_DATA_Image} alt={""} />
            </div>
          )}
        </List>
      </AppScrollbar>
      <Button
        sx={{
          borderRadius: 0,
          width: "100%",
          textTransform: "capitalize",
          marginTop: "auto",
          height: 40,
          borderBottomLeftRadius: "40px",
        }}
        variant="contained"
        color="primary"
        onClick={() => {
          onClose();
          dispatch(
            getNotificationsRequest({ type: "all", page: 1, limit: 10 })
          );
          dispatch(changePageNumber(0));
          history.push({
            pathname: `/notifications`,
          });
        }}
      >
        <IntlMessages id="common.viewAll" />
      </Button>
    </Box>
  );
};

export default AppNotificationContent;
