import { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import MetaMaskLogin from "../../../../../pages/auth/Signin/MetaMaskLogin";

import { toggleNavCollapsed } from "../../../../../redux/settings/actions";
import { useSidebarContext } from "../../../../utility/AppContextProvider/SidebarContextProvider";
import AppLogo from "../../components/AppLogo";
import HorizontalNav from "../../components/HorizontalNav";
import UserInfo from "../../components/UserInfo";
import AppNotifications from "../../../AppNotifications";
import { useDispatch, useSelector } from "react-redux";
import Gradients from "../../../../../shared/constants/Gradients";

const AppHeader = () => {
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const dispatch = useDispatch();
  const { sidebarMenuSelectedBgColor, sidebarMenuSelectedTextColor } =
    useSidebarContext();
  const auth: any = useSelector((state: any) => state?.auth?.user);
  const [scroll, setScroll] = useState(false);
 
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  return (
    <>
      <AppBar
        // position={scroll ? "fixed" : "static"}
        position="fixed"
        color="inherit"
        sx={{
          background: { xs: "rgba(57, 57, 57, 1)", lg: "transparent !important" },
          boxShadow: "none",
          mt:{lg:3,xs:0},
          width: {
            xs: "100%",
            lg: "100%",
          },
        }}
      >
        <Toolbar
          sx={{
            minHeight: { xs: 56, sm: 70 },
            px: { xs: 0 },
            pt: {lg:4,xs:0},
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: { lg: 1320, xl: 1420 },
              mx: "auto",
              px: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Hidden lgUp>
              <IconButton
                sx={{
                  marginRight: (theme) => theme.spacing(2),
                  color: "text.secondary",
                }}
                edge="start"
                className="menu-btn"
                color="inherit"
                aria-label="open drawer"
                onClick={() => dispatch(toggleNavCollapsed())}
                size="large"
              >
                <MenuIcon
                  sx={{
                    width: 35,
                    height: 35,
                  }}
                />
              </IconButton>
            </Hidden>

            <Box
              sx={{
                mr: 2,
                "& .app-logo": {
                  pl: 0,
                  mr: 0,
                },
                "& .logo-text": {
                  display: { xs: "none", sm: "block" },
                },
              }}
            >
              <AppLogo />
            </Box>
            <Hidden lgDown>
              <Box 
              // sx={{
              //      mx: { xs: -4, lg: -5 },
              //   }}
                className="headerBorder">
              <Box
              className="innerHeaderBox"
                sx={{
                  // width: "80% !important",
                  // ml: 5,
                  
                  // mx: "70px",
                  "& .navbarNav": {
                    display: "flex",
                    // my: { xs: 4, lg: 3 },
                    // mx: { xs: -4, lg: -5 },
                    // marginRight: -16,
                    // border: "2px solid green",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRadius: 6,
                    boxShadow: " 0px 0px 9px 6px rgba(0, 0, 0, 0.25)",
                    background: Gradients.navBarGradient,
                  },
                  "& .navItem": {
                    width: "auto",
                    cursor: "pointer",
                    // px: { xs: 4, lg: 5 },
                    // py: 1,
                    borderRadius: 1,
                    "&.active": {
                      color: sidebarMenuSelectedTextColor,
                      backgroundColor: alpha(sidebarMenuSelectedBgColor, 0.8),
                      "& .navLinkIcon": {
                        color: (theme) => theme.palette.secondary.main,
                      },
                    },
                  },
                  "& .navLinkIcon": {
                    mr: 2.5,
                    color: (theme) => theme.palette.common.white,
                    fontSize: 20,
                  },
                }}
              >
                <HorizontalNav />
              </Box>
              </Box>
            </Hidden>

            <Box
              sx={{
                flexGrow: 1,
              }}
            />

            <Box
              sx={{
                ml: 4,
                display: "flex",
                alignItems: "center",
              }}
            >
              {auth ? (
                <>
                  <AppNotifications
                    drawerPosition="right"
                    tooltipPosition="right"
                    sxNotificationContentStyle={{ width: 320 }}
                  />

                  <Box
                    sx={{
                      ml: { sm: 4, xs: 2 },
                      mr: { xs: 4, sm: 0 },
                      "& .user-info-view": {
                        p: 0,
                      },
                      "& .user-info": {
                        display: "none",
                      },
                    }}
                  >
                    <UserInfo />
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    ml: 4,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MetaMaskLogin />
                </Box>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default AppHeader;
