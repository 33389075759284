import firebase from "firebase/compat";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { useUrlSearchParams } from "use-url-search-params";
import { notificationCount } from "../../../redux/notifications/actions";
import {
  useLayoutActionsContext,
  useLayoutContext
} from "../../utility/AppContextProvider/LayoutContextProvider";
import { useSidebarActionsContext } from "../../utility/AppContextProvider/SidebarContextProvider";
import Layouts from "./Layouts";

// import firebase from '../../../firebase';
const AppLayout = () => {
  const dispatch = useDispatch();

  // const [name , setName] = useState<any>([]);
  // const [age , setAge] = useState();
  const { navStyle } = useLayoutContext();
  // const { isAuthenticated } = useAuthUser();
  const { updateNavStyle } = useLayoutActionsContext();
  const { updateMenuStyle, setSidebarBgImage } = useSidebarActionsContext();
  const AppLayout = Layouts[navStyle];
  const [params] = useUrlSearchParams({}, {});
  const auth: any = useSelector((state: any) => state?.auth?.user);

  useEffect(() => {
    if (params.layout) updateNavStyle(params.layout as string);
    if (params.menuStyle) updateMenuStyle(params.menuStyle as string);
    if (params.sidebarImage) setSidebarBgImage(true);

    Fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  //   useEffect(() => {

  //     window.addEventListener('beforeunload', handleTabClosing)
  //     // window.addEventListener('unload', handleTabClosing)
  //     return () => {
  //         window.removeEventListener('beforeunload', handleTabClosing)
  //         // window.removeEventListener('unload', handleTabClosing)
  //     }
  // })

  // const handleTabClosing = () => {
  //     // removePlayerFromGame()
  //     dispatch(logoutRequest());

  // }

  const alertUser = (event: any) => {
    event.preventDefault();
    event.returnValue = "";
  };

  window.addEventListener("load", () => {});
  const Fetchdata = () => {
    var leadsRef = firebase
      .database()
      .ref(`wallet-address/${auth?.walletAddress}`);

    leadsRef.on("value", function (snapshot) {
      var count = snapshot.child(`count`).val(); // "Ada"

      dispatch(notificationCount(count));
    });
  };

  return (
    <>
      {/* {isAuthenticated ? ( */}
      <AppLayout />
      {/* ) : (
        <AuthWrapper>
          <AppContentView />
        </AuthWrapper>
      )} */}
    </>
  );
};

export default React.memo(AppLayout);
