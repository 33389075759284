import { all, fork, put, select, takeLatest } from "redux-saga/effects";
import axios from "../../routes/axiosConfig";
import { sagaErrorHandler } from "../../shared/helperMethods/sagaErrorHandler";
import { makeSelectAuthToken } from "../store/selectors";
import {getNotificationsSuccess,getUnreadNotificationsSuccess } from "./actions";
import { GET_NOTIFICATION_REQUEST} from "./constants";
// import "react-toastify/dist/ReactToastify.css";
// import { toast } from "react-toastify";
// notification Saga //

function* getNotificationsSaga({ payload }: any): any {
  const token = yield select(makeSelectAuthToken());

  try {
    const response = yield axios.get(`/user/notifications?type=${payload.type}&limit=${payload.limit}&page=${payload.page}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // toast.success("Collection Successfully Registered")
if(payload.type==="all"){

  yield put(getNotificationsSuccess(response.data.data));
}
else{
  yield put(getUnreadNotificationsSuccess(response.data.data));
}
  } catch (error: any) {
    yield sagaErrorHandler(error.response);
  }
}

function* watchNotifications() {
  yield takeLatest(GET_NOTIFICATION_REQUEST, getNotificationsSaga);
}

export default function* notificationSaga() {
  yield all([fork(watchNotifications)]);
}
