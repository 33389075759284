export const CONNECT = "connect";
export const LOGOUT = "logout";
export const CONNECT_SUCCESS = "connect_success";
export const FORGOT_PASSWORD = 'forgot_password';
export const RESET_PASSWORD = 'reset_password';
export const SET_LOADER ="set_loader";
export const UPDATE_PROFILE = "update_profile";
export const UPDATE_PROFILE_SUCCESS = "update_profile_success";
export const SIGN1 = "sign_1";
export const SIGN2 = "sign_2";
export const SIGN1_SUCCESS = "sign_1_success";
export const SIGN2_SUCCESS = "sign_2_success";

export const GET_USER_REQUEST = "get_user_request";
export const GET_USER_SUCCESS = "get_user_success";

export const WITHDRAW_REQUEST = "withdraw_request";
export const WITHDRAW_SUCCESS = "withdraw_success";

export const DEPOSIT_REQUEST = "deposit_request";
export const DEPOSIT_SUCCESS = "deposit_success";

